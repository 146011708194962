import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "wordpress"
    }}>{`Wordpress`}</h1>
    <h2 {...{
      "id": "wordpress-setup"
    }}>{`Wordpress Setup`}</h2>
    <p>{`Una vez que se instaló wordpress desde runcloud, ingresas a la página.
Seguis pasos de instalación.`}</p>
    <ul>
      <li parentName="ul">{`Siempre elegir español (sin ningun país) como idioma `}</li>
      <li parentName="ul">{`Usuario ecomerciar y generar un password con bitwarden.`}</li>
      <li parentName="ul">{`Guardar credenciales en bitwarden.`}</li>
    </ul>
    <p>{`Una vez que estamos en el panel de control de Wordpress
1. Instalar Woocommerce Plugin e Activar.
1. Setear store en Argentina. Moneda Pesos. Poner Direcciòn y demas.
2. Instalarflatsome. Aparaiencia -> Temas -> Subir Tema desde el ZIP
1.  Este tema nos va a dar el UX builder.
3. Activar Flatsome -
1.  Crear un tema hijo con el nombre de la aplicación- Ejemplo - Cerveza Ocaso, el child se va llamar Cerveza Ocaso
2.  Saltear Segundo paso de instalar bloatware
3.  Y poner abajo return to dashboard.`}</p>
    <h2 {...{
      "id": "defining-urls-on-domain-change"
    }}>{`Defining URLs on domain change`}</h2>
    <p>{`Paste the following lines in the wp-config.php file inside the WordPress main folder`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`define( 'WP_HOME', 'http://example.com' );
define( 'WP_SITEURL', 'http://example.com' );
`}</code></pre>
    <h2 {...{
      "id": "plugins-de-wordpress"
    }}>{`Plugins de Wordpress`}</h2>
    <h3 {...{
      "id": "actualización"
    }}>{`Actualización`}</h3>
    <p><em parentName="p">{`Si hay que actualizar plugins seguir este orden por las dudas`}</em></p>
    <ul>
      <li parentName="ul">{`Todos los plugins menos woocommerce`}</li>
      <li parentName="ul">{`Woocommerce`}</li>
      <li parentName="ul">{`Flatsome`}</li>
      <li parentName="ul">{`Wordpress`}</li>
    </ul>
    <p>{`MercadoPago no se actualiza se deja en la versión que está porque las nuevas tienen problemas.
Se deja en V. n 3.0.16 `}</p>
    <h3 {...{
      "id": "plugins-que-se-suelen-usar"
    }}>{`Plugins que se suelen usar`}</h3>
    <ol>
      <li parentName="ol">{`Formularios y Mails --> WPForms y WPForms DB`}</li>
      <li parentName="ol">{`SVG -> Plugin Safe SVG`}</li>
      <li parentName="ol">{`Age Gate -> para popup de edad`}</li>
      <li parentName="ol">{`Landing Page -> SeedProd`}</li>
    </ol>
    <h3 {...{
      "id": "mercadopago-gateway-checkout"
    }}>{`MercadoPago Gateway Checkout`}</h3>
    <p>{`buscar las credentials-->
Estar logueado primero e ir `}<a parentName="p" {...{
        "href": "https://www.mercadopago.com/mla/account/credentials"
      }}>{`https://www.mercadopago.com/mla/account/credentials`}</a></p>
    <h2 {...{
      "id": "poner-en-privado-productos"
    }}>{`Poner en privado productos`}</h2>
    <p><em parentName="p">{`como poner en privado productos sin fotos por ejemplo`}</em></p>
    <ol>
      <li parentName="ol">{`Bajar el csv exportado desde woocommerce`}</li>
      <li parentName="ol">{`Borrar columnas que no sirven`}</li>
      <li parentName="ol">{`Filtrar por si están vacías las celdas de imágen`}</li>
      <li parentName="ol">{`Cambiarle a todas estos productos que aparecen después de filtrar el valor de Publicado a 0`}</li>
      <li parentName="ol">{`Sacar los filtros.`}</li>
      <li parentName="ol">{`Chequear que esté todo bien`}</li>
      <li parentName="ol">{`Volver a subir el CSV importandolo a woocommerce`}</li>
      <li parentName="ol">{`Tildar el casillero Actualizar productos existentes`}</li>
    </ol>
    <h3 {...{
      "id": "a-tener-en-cuenta"
    }}>{`A tener en cuenta`}</h3>
    <p>{`Siempre trabajar los CSVs con Google Sheets ya que excel puede alterar el formato de nuestros valores.`}</p>
    <h2 {...{
      "id": "como-reemplazar-email-woocommerce"
    }}>{`Como reemplazar email woocommerce.`}</h2>
    <p>{`Primero fijarse que email hay que reemplazar y a que script php corresponde dentro de woocommerce.`}</p>
    <p>{`Por ejemplo, cuenta nueva equivale a --> customer-new-account.php`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`Entonces dentro del editor de temas vamos a ir --> child theme
                                                        |
                                                        └──woocommerce
                                                                |
                                                                └── emails
                                                                        |
                                                                        └── crear nuevo archivo con el nombre, en este caso
                                                                                customer-new-account.php
`}</code></pre>
    <p>{`Un buen punto de comienzo es copiar el customer-new-account.php de woocommerce original e ir modificandoló.`}</p>
    <p>{`Pero una vez que exista el archivo que creamos dentro del tema hijo, este va a reemplazar al original dentro del plugin woocommerce.`}</p>
    <h2 {...{
      "id": "moova-patch---agregar-scripts-a-wordpress"
    }}>{`Moova Patch - agregar scripts a wordpress`}</h2>
    <p>{`El plugin de moova hoy estaría interfiriendo con Flatsome, por lo menos en cerveza ocaso paso eso.
El problema es que no actualizaba el precio del envio al cambiar direcciones. `}</p>
    <p>{`Para Arreglarlo, se puede inyectar un script en jquery que va a forzar el update cada vez que pasas de campo en campo.`}</p>
    <p>{`Para hacer esto, primero hay que crear un nuevo archivo .js
1. Abrir notepad.
2. copiar lo siguiente:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`
jQuery(document).ready(function($){ 
jQuery('#billing_state, #billing_city, #billing_address_1, #billing_postcode')
.on('change', function(){jQuery('body').trigger('update_checkout');});  
});



`}</code></pre>
    <p>{`Guardarlo como force_update.js`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Abrir el filezilla, o cliente de ftp, y conectarse al wordpress.`}</li>
      <li parentName="ol">{`Subir, dentro del child-theme, es decir --> carpeta nueva dentro de /wp-content/themes/child-theme/ llamada js. quedaría asi, ejemplo ocaso: /wp-content/themes/cerveza-ocaso/js`}</li>
      <li parentName="ol">{`Una vez que ya tenemos nuestro script en su lugar, vamos a agregarlo al functions.php.`}</li>
      <li parentName="ol">{`Podemos editarlo desde nuestro cliente ftp, o directo desde wordpress --> apariencia, editor de temas --> functions.php `}</li>
      <li parentName="ol">{`Ahi vamos a pegar este codigo -->`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
function moova_force_update() {
    // if(is_page(48)) {
        wp_register_script( 'force_the_update', get_stylesheet_directory_uri() . '/js/force_update.js', array(), null );
        wp_enqueue_script('force_the_update');
    // }
}

add_action( 'wp_enqueue_scripts', 'moova_force_update' );

`}</code></pre>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Guardamos y listo.`}</li>
    </ol>
    <h2 {...{
      "id": "funciones-utiles-de-woocommerce"
    }}>{`Funciones utiles de Woocommerce.`}</h2>
    <h3 {...{
      "id": "atributos-de-un-producto"
    }}>{`Atributos de un producto`}</h3>
    <p>{`Para traer todos los atributos de un producto siempre y cuando tengamos la variable global $product definida, mandamos la siguiente linea`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`echo wc_display_product_attributes( $product );
`}</code></pre>
    <p>{`Y nos va devolver una tablita tal cual la info adicional de los productos con toods los atributos custom.`}</p>
    <p>{`Si queremos ver los nombres de las categorias, tipo slug, podemos imprimir un array`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
  $array=get_post_meta($product->id);
                echo '<pre>';
                echo print_r($array);
                echo '</pre>';
`}</code></pre>
    <h3 {...{
      "id": "mostrar-solo-variacion-mas-barata-en-vez-de-rangos"
    }}>{`Mostrar solo variacion mas barata en vez de rangos`}</h3>
    <p>{`En functions.php`}</p>
    <p>{`Si queremos mostrar en la pagina de catalogo, shop, SOLAMENTE el precio mas barato en vez de un rango podemos usar esta funcion.`}</p>
    <p>{`Pasamos de $30-$100 a $30.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`// function to only have lowest price on 
function wc_varb_price_range( $wcv_price, $product ) {
 
    $prefix = '';
 
    $wcv_reg_min_price = $product->get_variation_regular_price( 'min', true );
    $wcv_min_sale_price    = $product->get_variation_sale_price( 'min', true );
    $wcv_max_price = $product->get_variation_price( 'max', true );
    $wcv_min_price = $product->get_variation_price( 'min', true );
 
    $wcv_price = ( $wcv_min_sale_price == $wcv_reg_min_price ) ?
        wc_price( $wcv_reg_min_price ) :
        '<del>' . wc_price( $wcv_reg_min_price ) . '</del>' . '<ins>' . wc_price( $wcv_min_sale_price ) . '</ins>';
 
    return ( $wcv_min_price == $wcv_max_price ) ?
        $wcv_price :
        sprintf('%s%s', $prefix, $wcv_price);
}
 
add_filter( 'woocommerce_variable_sale_price_html', 'wc_varb_price_range', 10, 2 );
add_filter( 'woocommerce_variable_price_html', 'wc_varb_price_range', 10, 2 );

`}</code></pre>
    <h3 {...{
      "id": "cambiarle-el-texto-al-botón-de-añadir-al-carrito-o-comprar"
    }}>{`Cambiarle el texto al botón de añadir al carrito, o comprar:`}</h3>
    <p>{`en functions.php`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`

// To change add to cart text on single product page
add_filter( 'woocommerce_product_single_add_to_cart_text', 'woocommerce_custom_single_add_to_cart_text' ); 
function woocommerce_custom_single_add_to_cart_text() {
    return __( 'Añadir al Carrito', 'woocommerce' ); 
}

// To change add to cart text on product archives(Collection) page
add_filter( 'woocommerce_product_add_to_cart_text', 'woocommerce_custom_product_add_to_cart_text' );  
function woocommerce_custom_product_add_to_cart_text() {
    return __( 'Añadir al Carrito', 'woocommerce' );

`}</code></pre>
    <h2 {...{
      "id": "editing-flatsome-files"
    }}>{`Editing flatsome files`}</h2>
    <h3 {...{
      "id": "como-editar-la-página-que-muestra-los-productos-shop"
    }}>{`Como editar la página que muestra los productos. SHOP`}</h3>
    <p>{`Ejemplo, quiero sacar el sidebar de widgets, o quiero cambiar la estructura html del listing de productos. El archivo a editar en flatsome es el siguiente:`}</p>
    <p>{`(woocommerce/content-product.php)`}</p>
    <h3 {...{
      "id": "pagina-del-producto-en-si"
    }}>{`Pagina del producto en si`}</h3>
    <p>{`Para modificar la pagina del producto en sí primero va a depender que layout tenemos elegido para mostrar el producto.`}</p>
    <p>{`Entonces, por ejemplo, si elegimos un product-page sin sidebar, el archivo será el siguiente:`}</p>
    <p>{`(woocommerce/single-product/layouts/product-no-sidebar.php)`}</p>
    <h3 {...{
      "id": "pagina-del-carrito"
    }}>{`Pagina del carrito`}</h3>
    <p>{`Para modificar la parte donde se ven los productos, con thumbnail
generalmente en tudominio.com/carrito/`}</p>
    <p>{`(woocommerce/cart/cart.php)`}</p>
    <h3 {...{
      "id": "pagina-del-checkout-final"
    }}>{`Pagina del checkout final`}</h3>
    <p>{`(woocommerce/checkout/form-checkout.php)`}</p>
    <h3 {...{
      "id": "los-thumbnails-en-el-checkout"
    }}>{`LOS THUMBNAILS en el checkout`}</h3>
    <p>{`Por ejemplo en Carne y en OCASO aparece el thumbnail en el order review, se edita este archivo.`}</p>
    <p>{`(woocommerce/checkout/review-order.php)`}</p>
    <h2 {...{
      "id": "agregar--y---al-minicart-de-woocommerce"
    }}>{`Agregar + y - al minicart de woocommerce`}</h2>
    <h3 {...{
      "id": "editar-el-archivo-de-flatsome-child-theme"
    }}>{`editar el archivo de flatsome child theme`}</h3>
    <ol>
      <li parentName="ol">{`Buscar el archivo mini-cart.php dentro de woocommerce y pegar en flatsome, especificamente en --> childtheme/woocommerce/cart/mini-cart.php`}</li>
      <li parentName="ol">{`Editar el archivo y pegar el siguiente codigo justo después de las siguientes lineas`}</li>
    </ol>
    <p>{`Dentro de mini-cart.php después de este codigo `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php echo apply_filters( 'woocommerce_widget_cart_item_quantity', '<span class="quantity">' . sprintf( '%s &times; %s', $cart_item['quantity'], $product_price ) . '</span>', $cart_item, $cart_item_key ); // phpcs:ignore WordPress.Security.EscapeOutput.OutputNotEscaped ?>

`}</code></pre>
    <p>{`Pegar este siguiente codigo.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php
$qty_start = '<input type="button" value="-" class="minus button is-form special-counter">';
$qty_end   = '<input type="button" value="+" class="plus button is-form special-counter">';

if ( empty( $max_value ) ) {
    $max_value = 9999;
}

if ( $max_value && $min_value === $max_value ) {
    ?>
    <div class="quantity hidden">
        <input type="hidden" id="<?php echo esc_attr( $input_id ); ?>" class="qty" name="<?php echo esc_attr( $input_name ); ?>" value="<?php echo esc_attr( $min_value ); ?>" />
    </div>
    <?php
} else {
        /* translators: %s: Quantity. */
        $label = ! empty( $args['product_name'] ) ? sprintf( __( '%s quantity', 'woocommerce' ), wp_strip_all_tags( $args['product_name'] ) ) : __( 'Quantity', 'woocommerce' );
        // Add wrapper classes
        $wrapper_classes = array('quantity','buttons_added');
        if(get_theme_mod('product_info_form')) {
            $wrapper_classes[] = 'form-'.get_theme_mod('product_info_form','normal');
        }
    ?>
    <div class="product-quantity">
    <div class="<?php echo implode(' ', $wrapper_classes);?>">
        <?php echo $qty_start; ?>
        <label class="screen-reader-text" for="<?php echo esc_attr( $input_id ); ?>"><?php echo esc_attr( $label ); ?></label>
        <input
            type="number"
            id="<?php echo esc_attr( $cart_item_key ); ?>"
            class="input-text qty text special-needs"
            step="<?php echo esc_attr( $step ); ?>"
            min="<?php echo esc_attr( $min_value ); ?>"
            max="<?php echo esc_attr( 0 < $max_value ? $max_value : '' ); ?>"
            name="<?php echo esc_attr( $input_name ); ?>"
            value="<?php echo esc_attr( $cart_item['quantity'] ); ?>"
            title="<?php echo esc_attr_x( 'Qty', 'Product quantity input tooltip', 'woocommerce' ); ?>"
            size="4"
            inputmode="<?php echo esc_attr( $inputmode ); ?>" />
        <?php echo $qty_end; ?>
    </div>
    </div>
    <?php
} ?>


`}</code></pre>
    <p>{`Una vez pegado hay que darle la funcionalidad.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">
        <p parentName="li">{`Crear un archivo llamado my_query.js en el siguiente directorio childtheme/js/my_query.js`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`En el functions.php pegar lo suguiente:`}</p>
      </li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`

//Script for changing quantity in minicart
wp_enqueue_script( 'ajax-script', get_stylesheet_directory_uri() .'/js/my_query.js', array('jquery') );
wp_localize_script( 'ajax-script', 'ajax_object',
            array( 'ajaxurl' => admin_url( 'admin-ajax.php' ) ) );
function update_item_from_cart() {
     $cart_item_key = $_POST['cart_item_key'];   
     $quantity = $_POST['qty'];     

    // Get mini cart
    ob_start();

    foreach (WC()->cart->get_cart() as $cart_item_key => $cart_item)
    {
        if( $cart_item_key == $_POST['cart_item_key'] )
        {
            WC()->cart->set_quantity( $cart_item_key, $quantity, $refresh_totals = true );
        }
    }
    WC()->cart->calculate_totals();
    WC()->cart->maybe_set_cart_cookies();
    do_action( 'woocommerce_cart_updated' ); 
    return true;
}

add_action('wp_ajax_update_item_from_cart', 'update_item_from_cart');
add_action('wp_ajax_nopriv_update_item_from_cart', 'update_item_from_cart');

`}</code></pre>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Y luego dentro del my_query.js que creamos pegar lo siguiente:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`
jQuery(document).on('change', '.special-needs', function (e)
    {
     e.preventDefault();
     var qty = jQuery(this).val();
     var cart_item_key =jQuery(this).attr("id");
   jQuery.ajax({
        type: 'POST',
        dataType: 'json',
        url: ajax_object.ajaxurl,
        data: {action : 'update_item_from_cart', 'cart_item_key' : cart_item_key, 'qty' : qty,  },
        success: function (data) {
            jQuery(document.body).trigger( 'wc_fragment_refresh' );
           console.log('Updated successfully.');
        if (data) {
            console.log('You missed something');
        }else{
            console.log('Updated Successfully');
        }
    }

    });

 });

`}</code></pre>
    <h2 {...{
      "id": "instrucciones-para-plugins"
    }}>{`Instrucciones para plugins`}</h2>
    <h3 {...{
      "id": "como-poner-animaciones-en-wordpress"
    }}>{`Como poner animaciones en wordpress.`}</h3>
    <p>{`Flatsome viene con animaciones, que se pueden poner desde el UX builder.
insertar imagen`}</p>
    <p>{`pero si no se puede, porque queres animar un elemento el cual flatsome no te da esa opcion, podemos usar el plugin animate it.`}</p>
    <p><a parentName="p" {...{
        "href": "https://es.wordpress.org/plugins/animate-it/"
      }}>{`https://es.wordpress.org/plugins/animate-it/`}</a></p>
    <p>{`El funcionamiento es muy simple, solo tenemos que agregar el plugin, y una vez instalado a cada elemento que queremos animar, le ponemos la clase sacada del generador de clases:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.downloads.eleopard.in/class-generator.html"
      }}>{`https://www.downloads.eleopard.in/class-generator.html`}</a></p>
    <p>{`ejemplo:  "animated spinner duration1"`}</p>
    <h2 {...{
      "id": "particularidades-de-páginas-hechas-por-ecomerciar"
    }}>{`Particularidades de páginas hechas por ecomerciar`}</h2>
    <h3 {...{
      "id": "abzez"
    }}>{`Abzez`}</h3>
    <p>{`El Discount del 100% de la segunda unidad se deshabilito haciendo lo siguiente:`}</p>
    <p>{`Comenté linea 98 de archivo cart-checkout.php (incl/cart-checkout.php)
Comenté linea 235 de archivo product.php (incl/product.php)`}</p>
    <p>{`Para agregarlo nuevamente hay que descomentar esas lineas. Pero habria que condicionar para ver si es compra o no primero y eso no está hecho todavia.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      